:root {
    font-size: 60%;
    --color-background: #f1f1f1;
    --color-primary-lighter: #6a994e;
    --color-primary-light: #5f8a46;
    --color-primary: #386641;
    --color-primary-dark: #325a3a;
    --color-primary-darker: #2c4e33;
    --color-secondary: #bc4749;
    --color-secondary-dark: #a84042;
    --color-tertiary: #A7C957;
    --color-tertiary-dark: #9AB951;
    --color-title-in-primary: #FFFFFF;
    --color-text-in-primary: #f2e8cf;
    --color-text-title: #383838;
    --color-text-complement: #FFFFFF;
    --color-text-base: #505050;
    --color-line-in-white: #E6E6F0;
    --color-input-background: #F8F8FC;
    --color-input-background-darker: #f0f0f5;
    --color-input: #414141;
    --color-button-text: #FFFFFF;
    --color-box-base: #FFFFFF;
    --color-box-footer: #FAFAFC;
    --color-chat-background: #ececec;
    --color-chat-message: #fcfcfc;
    --color-quaternary: #6A994E;
    --color-quaternary-dark: #5D8745;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100vh;
}

body {
    background: --var(--color-background);
}

#root {
    display: flex;
    align-items: center;
    justify-content: center;
}

body, input, textarea, button {
    font: 500 1.6rem Ubuntu;
}

Editor {
    font: 1.6rem Ubuntu !important;
}

.container {
    width: 90vw;
    max-width: 700px;
}

@media (min-width: 700px) {
    :root {
        font-size: 62.5%;
    }
}


/*Emblema "NOVO" <span className="new-badge"></span> */
.new-badge::after {
    content: "NOVO";
    font-size: 8px;
    background-color: #dc3545;
    color: #FFFFFF;
    padding: 1px 3px 1px 3px;
    border-radius: 4px;
    margin-top: 5px;
    margin-left: 3px;
    position: absolute;
}