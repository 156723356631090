.admins {
    padding-bottom: 0.5rem;
}

.admin {
    background: var(--color-box-base);
    border: 1px solid var(--color-line-in-white);
    border-radius: 0.8rem;
    margin-top: 7rem;
    overflow: hidden;
    margin-bottom: 4rem;
    padding-top: 1rem;
}

.admin+.admin {
    margin-top: 2rem;
}

.admin header {
    padding: 3.2rem 2rem;
    display: flex;
    align-items: center;
}

.admin header div {
    margin-left: 2.4rem;
}

.admin header div strong {
    font: 700 2rem Ubuntu;
    display: block;
    color: var(--color-text-title);
}

.admin a {
    text-decoration: none;
}

.admin>p {
    padding: 0 4.5rem;
    font: 500 1.6rem Ubuntu;
    line-height: 2.8rem;
    color: var(--color-text-base);
    padding-bottom: 2.4rem;
}

.admin footer {
    padding: 3.2rem 2rem;
    background: var(--color-box-footer);
    border-top: 1px solid var(--color-line-in-white);
    margin-top: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.admin footer button {
    width: 19rem;
    height: 5.6rem;
    background: var(--color-secondary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 700 1.4rem Ubuntu;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: 0.2s;
    outline: 0;
}

.admin footer button:hover, .access-button:hover {
    background: var(--color-secondary-dark);
}

@media (min-width: 700px) {
    .admin {
        margin-top: 4rem;
    }
    .admin+.admin {
        margin-top: 1rem;
    }
    .admin>p {
        padding-left: 5.6rem;
    }
    .admin footer button {
        width: 22rem;
        font-size: 1.6rem;
        justify-content: center;
        margin-right: 1rem;
    }
}

.AC-Title {
    font-size: 20px !important;
    font-weight: bold !important;
}

.AC-Desc {
    font-size: 15px !important;
}

.AC-Text {
    font-size: 18px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.AC-Border {
    border-top: 1px solid #5a5a5a !important;
}

.access-button {
    width: 19rem;
    height: 5.6rem;
    background: var(--color-secondary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 700 1.4rem Ubuntu;
    transition: 0.2s;
    outline: 0;
    margin-top: 20px;
    position: relative;
    right: -525px;
}

.grid-container-title {
    background-color: #FAFAFC;
    position: relative;
    top: 30px;
    justify-content: left;
    display: grid;
    grid-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: 50px 400px 290px;
    font-family: Arial;
    font-size: 22px;
    font-weight: 550;
    line-height: 24px;
    color: #4C4C4D;
    border-radius: 10px;
    margin-bottom: 40px;
}

.grid-item-title {
    padding: 10px;
}

.grid-space {
    content: " ";
    margin-bottom: 40px;
}

.grid-container {
    background-color: #FFFFFFCE;
    justify-content: left;
    display: grid;
    grid-gap: 10px;
    grid-row-gap: 50px;
    grid-template-columns: 50px 400px 160px 40px 40px;
    font-family: Arial;
    font-size: 22px;
    line-height: 24px;
    color: #4C4C4D;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: all 1s;
}

.grid-container:hover {
    background-color: #FFFFFF;
    color: #242424;
}

.grid-item {
    padding: 20px;
}

.grid-item-button {
    cursor: pointer !important;
    transition: all 1s;
}

.grid-item-button:hover {
    color: #000000;
}

.new-faq {
    position: absolute;
    color: #6A994E;
    left: 72rem;
    transition: all 500ms;
}

.new-faq:hover {
    cursor: pointer;
    color: #A7C957;
}