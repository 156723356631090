.page-header {
    display: flex;
    flex-direction: column;
    background-color: var(--color-primary);
}

.page-header .top-bar-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-text-in-primary);
    padding: 1.6rem 0;
}

.page-header .top-bar-container a {
    height: 2.2rem;
    transition: opacity 0.2s;
}

.page-header .top-bar-container a:hover {
    opacity: 0.6;
}

.page-header .top-bar-container .logout {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background: none;
    border: none;
    text-decoration: none;
}

.page-header .top-bar-container .logout:hover {
    opacity: 0.6;
}

.page-header .top-bar-container > img {
    height: 4.2rem;
}

.page-header .header-content {
    width: 90%;
    margin: 0 auto 1rem;
    position: relative;
    margin: 3.2rem;
}

.page-header .header-content strong {
    font: 700 2.6rem Ubuntu;
    line-height: 3.2rem;
    color: var(--color-title-in-primary);
}

.page-header .header-content p {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: var(--color-text-in-primary);
    margin-top: 2.4rem;
    margin-bottom: 2rem;
}

@media (min-width: 700px) {
    .page-header {
        height: 320px;
    }

    .page-header .top-bar-container {
        max-width: 1100px;
    }

    .page-header .header-content {
        flex: 1;
        max-width: 740px;
        margin: 0 auto;
        padding-bottom: 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .page-header .header-content strong {
        max-width: 600px;
        font: 700 3.2rem Ubuntu;
        line-height: 4.2rem;
        color: var(--color-title-in-primary);
    }

}