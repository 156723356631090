html { 
    overflow-y: scroll; 
}

#page-credits {
    width: 100%;
    height: auto;
    min-width: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;

    color: var(--color-text-in-primary);
    background: var(--color-primary);

    padding-top: 10%;
}

#page-credits .go-back {
    display: block;
    position: absolute;
    top: 0px;

    background-color: var(--color-primary);
    color: var(--color-text-in-primary);
    width: 90%;
    padding: 1.6rem 0 0 2rem;
    margin: 1rem 0 10% 10%;
}

#page-credits-content {
    position: absolute;
    align-items: center;
    justify-content: center;
    align-self: center;

    width: 68%;
    height: auto;
    min-width: 28%;
    max-width: 120rem;
    border-radius: 0.8rem;
    margin: 1% auto 1rem;
    padding: 3rem 3rem 4rem 3.5rem;

    background: var(--color-box-base);
}

.page-credits-content .team-title {
    margin-top: 4rem;
}

.heads-list {
    display: inline;
    align-items: center;
    justify-content: left;
    column-gap: 8%;

    outline: 0;
    font: 1.6rem Ubuntu;
    color: var(--color-input);
}

.head {
    position: relative;
    margin: 1rem 1rem 2rem 3rem;
}

.head p {
    padding-bottom: 0.5rem;
}

.head p + p{
    padding-bottom: 0.2 !important;
    color: slategrey;
}

.team-list {
    display: inline;
    align-items: center;
    justify-content: left;
    column-gap: 11%;
    
    outline: 0;
}

.team-list ul {
    font: 1.6rem Ubuntu;
    color: var(--color-input);
}

.team p {
    margin-top: 2.5rem;
}

.devs {
    position: relative;
    margin: 2.5rem 0 0 3rem;
}

.support {
    position: relative;
    margin: 2.5rem 0 0 3rem;
}

.devs li {
    padding-bottom: 1rem;
}

.support li {
    padding-bottom: 1rem;
}

.team p {
    font: 1.8rem Ubuntu;
    color: grey;
}

#page-credits-content img {
    position: relative;
    width: 25px;
    height: 25px;
    margin-bottom: -7px;
}

a {
    margin-left: 8px;
}

a + a {
    margin-left: 0 !important;
}

.page-credits-content h2 {
    text-align: initial;
    justify-content: left;
    font-size: 2.4rem;
    margin-bottom: 1.2rem;
    margin-top: 1.8rem;
    color: grey;
}

@media (min-width: 1000px) {
    #page-credits {  
        height: auto;
        min-height: 100%;
        padding-top: 7%;
    }

    #page-credits-content {
        width: 78%;
        height: auto;

        position: absolute;
        align-items: center;
        justify-content: center;
        align-self: center;
    }

    #heads-list {
        display: grid;
        grid-template-columns: 2fr 2fr;
        align-items: initial;
        justify-content: left;
    }

    #team-list {
        display: flex;
        align-items: initial;
        justify-content: space-between;
        margin-left: 0.5rem;
    }

    .team {
        width: 50%;
    }

    #page-credits .go-back {
        margin: 2rem 0 2rem 8% !important;
    }

}


@media (min-width: 1400px) {
    #page-credits {  
        padding-top: 5%;
    }
}