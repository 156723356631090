body {
    background-color: #F5F5F6;
}

.logout {
    cursor: pointer !important;
}

#page-profile {
    width: 100vw;
    height: 100vh;
}

#page-profile .page-header .header-content {
    margin-bottom: 6.4rem;
}

#menu-items {
    margin: -3.2rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}

#menu-items .input-block {
    position: relative;
}

#menu-items .input-block #agenda,
#menu-items .input-block #help {
    margin-top: -1.4rem !important;
}

#menu-items .input-block button {
    width: 100%;
    height: 5.6rem;
    border-radius: 0.8rem;
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem Ubuntu;
    cursor: pointer;
}

#menu-items .input-block:focus-within::after {
    width: calc(100% -3.2rem);
    height: 2px;
    content: '';
    background: var(--color-tertiary);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
}

.on {
    color: var(--color-text-title);
    background-color: #F5F5F6;
    border: 1px solid;
    border-color: #f1f1f3;
}

.off {
    color: var(--color-text-complement);
    background-color: var(--color-quaternary);
    border: 1px solid;
    border-color: var(--color-quaternary-dark);
}

#page-profile main {
    margin: 3.2rem auto;
    width: 94%;
}

#page-profile main .without-notifications,
#page-profile main .without-help {
    position: flex;
    color: var(--color-text-base);
    max-width: 100%;
    padding: 25% 25% 15% 25%;
    font: 500 1.6rem Ubuntu;
    line-height: 2.8rem;
    align-self: center;
    justify-content: space-evenly;
    text-align: center;
}

.open-blip-desk {
    padding: 1rem 0;
    margin-left: 1rem;
    margin-top: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -8%;
    padding-bottom: 5rem;
}

.open-blip-desk button {
    width: 32rem;
    height: 5.6rem;
    border: 0;
    border-radius: 3rem;
    cursor: pointer;
    font: 700 1.6rem Ubuntu;
    transition: 0.2s;
    outline: 0;

    background: var(--color-tertiary);
    color: var(--color-button-text);

    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.open-blip-desk button:hover {
    background: var(--color-tertiary-dark);
}

.agenda-buttons {
    padding: 1rem 0;
    margin-left: 1rem;
    margin-top: 5.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -8%;
}

.agenda-buttons button {
    width: 20rem;
    height: 5.6rem;
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 700 1.4rem Ubuntu;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: 0.2s;
    outline: 0;
}

.save-schedules button {
    margin-left: 20%;
}

.agenda-buttons .new-schedule button {
    background: var(--color-tertiary);
}

.agenda-buttons .new-schedule button:hover {
    background: var(--color-tertiary-dark);
}

.agenda-buttons .save-schedules button {
    background: var(--color-secondary);
}

.agenda-buttons .save-schedules button:hover {
    background: var(--color-secondary-dark);
}

#page-profile main .without-schedules {
    position: flex;
    color: var(--color-text-base);
    max-width: 100%;
    padding: 13% 25% 15% 25%;
    font: 500 1.6rem Ubuntu;
    line-height: 2.8rem;
    align-self: center;
    justify-content: space-evenly;
    text-align: center;
}

@media (min-width: 700px) {
    #page-profile {
        max-width: 100%;
        margin-top: -5.4rem;
    }

    #page-profile .page-header .header-content {
        margin-bottom: 0;
    }

    #menu-items {
        margin: -3.6rem auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;
        width: 90%;
        max-width: 760px;

    }

    #page-profile .page-header .header-content {
        margin-bottom: 2.4rem;
    }

    #page-profile main {
        padding: 3.2rem 0;
        max-width: 760px;
        margin: 0 auto;
    }

    #page-profile header,
    #page-profile footer {
        padding: 3.2rem;
    }

    #page-profile footer,
    .agenda-buttons {
        justify-content: flex-end;
    }

    #page-profile main .without-notifications,
    #page-profile main .without-help {
        max-width: 100%;
        padding: 25% 25% 13% 25%;
        align-self: center;

    }

    .agenda-buttons {
        padding: 0;
    }

    .agenda-buttons button {
        width: 20rem;
        font-size: 1.6rem;
        justify-content: center;
        margin-left: 2rem;
    }

}