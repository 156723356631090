.schedule {
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.item-box {
    background: var(--color-box-base);
    border: 1px solid var(--color-line-in-white);
    border-radius: 0.8rem;
    padding-top: 1rem;
    padding-left: 4rem;

    margin-bottom: 2.5rem;
    margin-top: 1rem;
}

.schedule-item {
    overflow: hidden;
    margin-top: -1rem;
    padding-bottom: 3.5rem;
    padding-right: 4rem;
}

.schedule-item + .schedule-item {
    margin-top: 0 !important;
}

.removeButton {
    padding: 0.5rem;
    margin-right: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.removeButton button {
    width: 4.6rem;
    height: 4.6rem;
    background: var(--color-input-background);
    color: var(--color-secondary);
    border: 0;
    border-radius: 5rem;
    cursor: pointer;
    font: 700 1.4rem Ubuntu;
    transition: 0.2s;
    outline: 0;
}

.removeButton button:hover {
    background: var(--color-input-background-darker);
}

@media (min-width: 700px) {
    .schedule {
        padding-bottom: 1rem;
    }
    .schedule-item {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        column-gap: 1.6rem;
    }
    
    .schedule-item + .schedule-item {
        margin-top: 1.0rem;
    }
    
    .schedule-item .input-block {
        margin: 0 !important;
    }
    
}