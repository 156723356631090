.select-block {
    position: relative;
}

.select-block + .select-block {
    margin-top: 1.4rem;
}

.select-block label {
    font-size: 1.4rem;
    color: var(--color-text-base);
}

.select-block select {
    width: 100%;
    height: 5.6rem;
    margin-top: 0.8rem;
    margin-bottom: 1.2rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-white);
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem Ubuntu;
    color: var(--color-input);
}

.select-block:focus-within::after {
    width: calc(100% - 3.2rem);
    height: 2px;
    content: '';
    background: var(--color-primary-light);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
}