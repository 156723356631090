#page-login {
    min-width: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: var(--color-text-in-primary);
    background: var(--color-primary);
}

#page-login .go-back {
    display: block;
    position: fixed;
    top: 0px;

    background-color: var(--color-primary);
    color: var(--color-text-in-primary);
    width: 90%;
    padding: 4.6rem 0 10.6rem 0;
    margin: 0 0 10% 10%;
}

#page-login form {
    position: relative;
    background: var(--color-box-base);
    width: 40%;
    height: 40%;
    min-height: 40rem;
    max-height: 60rem;
    min-width: 28%;
    max-width: 50rem;
    border-radius: 0.8rem;
    margin: 2% auto 1rem;
    overflow: hidden;

    padding: 2.6rem;
    align-items: center;
    justify-content: center;
}

#page-login form input {
    width: 97%;
    height: 5.6rem;
    margin: 0.8rem;
    border-radius: 0.8rem;
    background: var(--color-input-background);
    border: 1px solid var(--color-line-white);
    outline: 0;
    padding: 0 1.6rem;
    font: 1.6rem Ubuntu;
    color: var(--color-input);
}

#page-login form h1 {
    font-size: 2.4rem;
    line-height: 2.6rem;
    color: var(--color-primary);
    margin-top: 2.0rem;
    margin-bottom: 4.0rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

#page-login form button {
    width: 100%;
    height: 5.6rem;
    background: var(--color-tertiary);
    color: var(--color-button-text);
    border: 0;
    border-radius: 0.8rem;
    cursor: pointer;
    font: 700 1.6rem Ubuntu;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.2s;
    margin-top: 3.2rem;
    margin-bottom: 2.0rem;
}

#page-login button:hover {
    background: var(--color-tertiary-dark);
}

@media (max-width: 1000px) {
    #page-login {
        max-width: 100vw;
    }

    #page-login form {
        width: 80%;
        min-width: 40rem;
        margin: 10% auto 2rem;
    }

    #page-login .go-back {
        top: 20px;
        padding: 0rem 0 10.6rem 0;
    }

}