#page-landing {
    width: 100%;
    height: auto;
    min-height: 100%;

    display: flex;
    align-items: center;
    align-self: baseline;
    justify-content: center;
    flex-flow: wrap;

    color: var(--color-text-in-primary);
    background: var(--color-primary);
}

.page-landing-content {
    width: 100%;
    min-height: 100%;
    height: auto;
    background: var(--color-primary);  
}

.page-landing-help {
    max-width: 80%;
    height: 50%;
}

#page-landing-help {
    border: none;
    background: var(--color-primary);  
}

#page-landing-help.help {
    margin-top:0;
    border-radius:0;
}

#page-landing-help h2 {
    line-height: 3.6rem;
    margin-left: 1rem;
    padding-top: 2rem;

    display: flex;
    justify-content: left;
    color: var(--color-text-in-primary);
}

.logo-container img, h1 {
    margin-top: 9rem;
    height: 9rem;
    color: var(--color-title-in-primary);
    font-size: 3.4rem;
}

.hero-image {
    min-height: 500px;
}

.logo-container h1 {
    padding-top: 1rem;
}

.hero-image {
    width: 80%;
    text-align: center;
    margin-top: -2rem;
    margin-bottom: 1rem;
    margin-left: 12%;
    max-height: 42rem;
    max-width: 50rem;
    
}

.logo-container {
    text-align: center;
}

.logo-container h2 {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 3.6rem;
    margin-top: 1.4rem;

    display: flex;
    justify-content: center;
}

.team-button img {
    display: inline-block;
    position: absolute;
    width: 35px;
    height: 35px;
    bottom: 92%;
    left: 47%;

    background-color: var(--color-primary);
    color: var(--color-text-in-primary);
}

.buttons-container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 20px;
    background-color: var(--color-primary);
}

.buttons-container a {
    width: 26rem;
    height: 10.4rem;
    border-radius: 0.8rem;
    margin-right: 2.6rem;
    font: 700 1.8rem Ubuntu;

    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    color: var(--color-button-text);

    transition: background-color 0.2s;
}

.buttons-container a.register {
    background: var(--color-secondary);
}

.buttons-container a.register:hover {
    background: var(--color-secondary-dark);
}

.buttons-container a.login {
    background: var(--color-primary-lighter);
}

.buttons-container a.login:hover {
    background: var(--color-primary-light);
}

/* Conversão desktop */
@media (min-width: 1100px) {
    #page-landing {
        min-height: 100%;
        min-width: 100%;
        max-width: 100%;
        height: auto;
        display: block;
        align-items: inline;
        align-self: baseline;
        
        padding-left: 10%;
        padding-right: 10%;
    }

    #page-landing-content {
        min-height: 400px;
        padding-bottom: 5rem;
        align-items: center;
        align-self: center;
        
        display: grid;
        grid-template-columns: 3fr 2fr;
        column-gap: 15%;
        
        grid-template-areas: 
            "title logo"
            "buttons none"
        ;
    }

    #page-landing-help {
        max-width: 96%;
        align-self: initial;
        border: none;
        background: var(--color-primary); 
        
    }

    #page-landing-help h2 {
        padding-top: 3rem;
    }

    .team-button img {
        display: inline-block;
        position: absolute;
        bottom: 90%;
        left: 94%;

        background-color: var(--color-primary);
        color: var(--color-text-in-primary);
        width: 40px;
        height: 40px;
        
    }

    .logo-container {
        grid-area: title;
        align-self: center;
        text-align: left;
        margin: 0;
    }

    .logo-container h2 {
        text-align: initial;
        justify-content: left;
        font-size: 2.4rem;
        margin-bottom: 2.2rem;
        margin-top: 1.8rem;

        color: var(--color-title-in-primary);
    }

    .logo-container img, h1 {
        margin-top: -3%;
        height: 100%;
        font-size: 4.6rem;
        margin-bottom: 4.2rem;   
    }

    .hero-image {
        grid-area: logo;
        width: 100%;
        height: 100%;
        min-height: 40rem;
        min-width: 35rem;
        align-self: center;
        justify-self: center;
        margin-left: 20%;
        margin-top: 10%;
        max-height: 100%;
        max-width: 100%;
    }

    .buttons-container {
        grid-area: buttons;
        justify-content: flex-start;
        height: 8.0rem;
        margin-top: 1%;
        margin-bottom: 2rem;
        margin-left: 0px;
    }

    .buttons-container a {
        font-size: 2.3rem;
    }
}

@media (min-width: 1500px) {
    #page-landing-content {
        padding-top: 5rem;
        grid-template-columns: 800px;
        column-gap: 20%;
    }

    #page-landing-help h2 {
        padding-top: 10rem;
    }

    .team-button img {
        bottom: 92%;
        left: 94%;
    }
}